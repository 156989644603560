<div class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/logo.png" alt="anotaai" style="max-width: 100px;">
        </div>
        <div class="title-container">
            <p>Ops!!!</p>
        </div>
        <div *ngIf="!errorUrl" class="subtitle-container">
            Você já respondeu esse formulário!
        </div>
        <div *ngIf="errorUrl" class="subtitle-container">
            <span>Há algo de errado com o seu acesso</span>
            <span>Entre em contato com a nossa equipe!</span>
        </div>
    </div>
</div>