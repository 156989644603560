import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Observable, Subscription } from 'rxjs';
import { Toast } from './shared/interfaces/toast.interface';
import { AnotaLoaderService } from './shared/services/ds-services/anota-loader.service';
import { AnotaToastService } from './shared/services/ds-services/anota-toast.service';
import { MixPanelService } from './shared/services/mix-panel/mix-panel.service';
import { RegisterService } from './shared/services/register-service/register.service';
import { AmplitudeAnalyticsService } from './shared/services/amplitude/amplitude-analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'onboarding-register-frontend';
  toast: Toast = {
    show: false,
    type: 'success',
    message: ''
  }

  showToast$: Observable<Toast>;
  showToastSubscription: Subscription;

  public showLoader: boolean = false;
  public showLoader$: Observable<boolean>;
  public showLoaderSubscription: Subscription;
  

  constructor(
    private anotaToastService: AnotaToastService,
    private anotaLoaderService: AnotaLoaderService,
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private mixPanelService: MixPanelService,
    private amplitudeAnalyticsService: AmplitudeAnalyticsService,
  ){}

  ngOnInit(): void {
    this.mixPanelService.startService();
    this.router.events.forEach(
      (event) => {
        if (event instanceof NavigationEnd) {
          this.gtmService.pushTag({
            event: 'page',
            pageName: event.url
          });
        }
      }
    )

    this.amplitudeAnalyticsService.initializeAmplitudeAnalytics();

    this.showToast$ = this.anotaToastService.getShowToast();
    this.showToastSubscription = this.showToast$.subscribe((value: Toast) => {
      this.toast = value;
    });

    this.showLoader$ = this.anotaLoaderService.getShowLoader();
    this.showLoaderSubscription = this.showLoader$.subscribe((value: boolean) => {
        this.showLoader = value;
    });
  }
}
