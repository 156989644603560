<div class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/logo.svg" style="height: 50px;" alt="anotaai">
        </div>

        <div class="steps-container">
            <div class="progress-bg">
                <div class="bar" style="--progress-bar: {{step}}"></div>
            </div>
            <div class="step" [ngClass]="{'active': step == 0, 'checked': step > 0}">
                <div class="icon">
                <anota-icon iconName="clock"></anota-icon>
                </div>
            <!-- <span class="text">Dados</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step == 1, 'checked': step > 1}">
                <div class="icon">
                <anota-icon iconName="deliverymen" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Responsável</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step >= 2}" [ngClass]="{'active': step == 2, 'checked': step > 2}">
                <div class="icon">
                <anota-icon iconName="map-pin" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Dados</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step === 3}" [ngClass]="{'active': step == 3, 'checked': step > 3}">
                <div class="icon">
                <anota-icon iconName="dollar-sign" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Endereço</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step === 4}" [ngClass]="{'active': step == 4, 'checked': step > 4}">
                <div class="icon">
                    <anota-icon iconName="image" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Teste</span> -->
                </div>
            </div>
        <br>

        <div class="title-container">
            <p>Nos envie uma foto do seu cardápio</p>
        </div>
        <div class="subtitle-container">
            <span>Envie fotos e/ou arquivos em PDF do seu cardápio para cadastrarmos suas categorias, itens, valores, promoções e outros</span>
        </div>
        <div  [ngClass]="documents.length > 1 ? 'container-images' : 'container-nth'">
            <div id='input-image' class="images" *ngFor="let document of documents; let i = index">
                <div style="display: flex;align-items:center; position: relative;">
                    <anota-input-document
                    [messageError]="messageError"
                    [docInputSize]="10"
                    label="Arquivo ou Foto do Cardápio"
                    [(ngModel)]="document.url" 
                    (anotaImageChange)="changeDocsMenu($event, i)" 
                    ></anota-input-document>
                    <anota-button *ngIf="i === documents.length - 1 && document.url" [style.right]="'-50px'" icon="plus-circle" type="link" size="lg" 
                    (click)="addDocsMenu(i)" style="position: absolute;" ></anota-button>
                </div>
            </div>
        </div>
        <div class="tips">
            <img src="../../assets/group-menu.svg" alt="anotaai">
            <div>
                <div style="display: grid;">
                    <span style="font-weight: bold;">
                        <anota-icon iconName="message-circle" iconColor="#0098FC"></anota-icon>
                        Dicas:</span>
                    <span style="width: 200px;">Ative a função de enquadrar  foto de seu celular.</span>
                </div>
                <br>
                <div style="display: grid;">
                    <span style="font-weight: bold;">PDF:</span>
                    <span style="width: 200px;">Envie arquivos com extensão PDF.</span>
                </div>
            </div>
        </div>
        <div class="title-link" *ngIf="!sessionForm?.menu_link && !linkVerify" (click)="verifyLink($event)">
            <span>Quero enviar um link de site, cardápio iFood, drive ou outro</span>
        </div>
        <form [formGroup]="myForm" novalidate *ngIf="sessionForm?.menu_link || linkVerify">
            <div class="input-container">
                <br>
                <div>
                    <br>
                    <anota-input-text 
                        size="lg"
                        [messageError]="messageError"
                        label="Link do Cardápio"
                        placeholder="http://onboarding-register..."
                        formControlName="menu_link"
                        helperText="Envie um link de site, drive ou outro que contenha as informações dos seus itens"
                    ></anota-input-text>
                </div>
            </div>
        </form>
        <br><br>
    </div>
    <anota-footer [noSidebar]="true" [noMenuFooter]="true" class="footer">
        <div class="button">
            <anota-button size="lg" type="outline-primary" (click)="returnPage()">Anterior</anota-button>
            <anota-button size="lg" (click)="createForm()">Próximo</anota-button>
        </div>
    </anota-footer>
</div>