import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

import { FinalInformationsComponent } from './final-informations/final-informations.component';
import { FinalizeComponent } from './finalize/finalize.component';
import { DsModule } from "@githubanotaai/design-system";
import { EstablishmentModule } from './establishment/establishment.module';
import { AddressModule } from './address/address.module';
import { OperationHoursModule } from './operation-hours/operation-hours.module';
import { DeliveryInformationsModule } from './delivery-informations/delivery-informations.module';
import { AttendanceLocationModule } from './attendance-location/attendance-location.module';
import { PaymentInformationsModule } from './payment-informations/payment-informations.module';
import { VerifyInformationsComponent } from './verify-informations/verify-informations.component';
import { environment } from 'src/environments/environment';
import { ErrorComponent } from './error/error.component';
import { UtmRedirectComponent } from './utm-redirect/utm-redirect.component';
import { InstagramComponent } from './instagram/instagram.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FinalInformationsComponent,
    VerifyInformationsComponent,
    FinalizeComponent,
    ErrorComponent,
    UtmRedirectComponent,
    InstagramComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DsModule,
    HttpClientModule,
    FormsModule,
    NgxMaskDirective, 
    NgxMaskPipe,
    SharedModule,
    BrowserAnimationsModule,
    EstablishmentModule,
    AddressModule,
    OperationHoursModule,
    DeliveryInformationsModule,
    AttendanceLocationModule,
    PaymentInformationsModule,
    GoogleTagManagerModule.forRoot({
      id: environment.TAG_MANAGER_ID,
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.GOOGLE_ANALYTICS_ID)
    
  ],
  providers: [
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
