<div class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/logo.svg" style="height: 50px;" alt="anotaai">
        </div>

        <div class="steps-container">
            <div class="progress-bg">
                <div class="bar" style="--progress-bar: {{step}}"></div>
            </div>
            <div class="step" [ngClass]="{'active': step == 0, 'checked': step > 0}">
                <div class="icon">
                <anota-icon iconName="clock"></anota-icon>
                </div>
            <!-- <span class="text">Dados</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step == 1, 'checked': step > 1}">
                <div class="icon">
                <anota-icon iconName="deliverymen" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Responsável</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step >= 2}" [ngClass]="{'active': step == 2, 'checked': step > 2}">
                <div class="icon">
                <anota-icon iconName="map-pin" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Dados</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step === 3}" [ngClass]="{'active': step == 3, 'checked': step > 3}">
                <div class="icon">
                <anota-icon iconName="dollar-sign" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Endereço</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step === 4}" [ngClass]="{'active': step == 4, 'checked': step > 4}">
                <div class="icon">
                    <anota-icon iconName="image" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Teste</span> -->
                </div>
            </div>
        <br>
        <div class="instagram-icon">
            <img src="../../assets/logo_instagram_svg.svg" alt="anotaai">
        </div>
        <br>
        <div class="subtitle-container">
            <span>Estamos ansiosos para seguir você! Compartilhe o Instagram do seu estabelecimento para que possamos mencioná-lo nas nossas publicações.</span>
        </div>
        <form [formGroup]="myForm" novalidate>
            <div class="input-container">
                <br>
                <div>
                    <br>
                    <anota-input-group 
                        size="lg"
                        [messageError]="messageError"
                        label="Informe o instagram do estabelecimento"
                        placeholder="@anotaai.bots"
                        searchPosition="left" 
                        icon="instagram"  
                        formControlName="page_instalink"
                    ></anota-input-group>
                </div>
            </div>
        </form>
        <div class="title-link" *ngIf="!sessionForm?.page_instalink && !linkVerify" (click)="verifyLink($event)">
            <span>Informar depois</span>
        </div>
    </div>
    <anota-footer [noSidebar]="true" [noMenuFooter]="true" class="footer">
        <div class="button">
            <anota-button size="lg" type="outline-primary" (click)="returnPage()">Anterior</anota-button>
            <anota-button size="lg" (click)="createForm()" [disabled]="myForm.invalid && !linkVerify">Próximo</anota-button>
        </div>
    </anota-footer>
</div>