import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterForm } from "src/app/shared/interfaces/register-form";
import { AnotaToastService } from "../shared/services/ds-services/anota-toast.service";
import { RegisterService } from "../shared/services/register-service/register.service";
import { SessionStorageService } from "../shared/services/session-storage/session-storage.service";
import { MixPanelService } from "../shared/services/mix-panel/mix-panel.service";


@Component({
    selector: 'app-operation-hours',
    templateUrl: 'operation-hours.component.html',
    styleUrls: ['operation-hours.component.scss']
})
export class OperationHoursComponent implements OnInit {
    public myForm: UntypedFormGroup;
    public pageId;
    public sessionForm;
    public registerForm: RegisterForm = {};
    public days: Array<any> = [];
    public selected: Array<any> = [];
    public window_size = screen.width;
    public step: number = 0;

    public weekDays: Array<any> = [
        {
            name: 'Domingo',
            short_name: 'sun'
        },
        {
            name: 'Segunda',
            short_name: 'mon'
        },
        {
            name: 'Terça',
            short_name: 'tue'
        },
        {
            name: 'Quarta',
            short_name: 'wed'
        },
        {
            name: 'Quinta',
            short_name: 'thu'
        },
        {
            name: 'Sexta',
            short_name: 'fri'
        },
        {
            name: 'Sábado',
            short_name: 'sat'
        },
    ]

    constructor(
        private registerService: RegisterService,
        private sessionStorageService: SessionStorageService,
        private anotaToastService: AnotaToastService,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
		private route: ActivatedRoute,
        private mixPanelService: MixPanelService
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.sessionStorageService.pageId = this.pageId;
        this.sessionForm = this.registerService.getform();
    }
    
    ngOnInit(){
        this.initializeForm();
        if(this.sessionForm.week && this.sessionForm.week.length != 0){
            this.sessionForm.week.forEach((element:any)=>{
                if(!element.name){
                    this.weekDays.forEach((day)=>{

                        if(element.short_name == day.short_name){
                            this.days = this.days.concat([{
                                name: day.name,
                                schedules: element.schedules,
                                selected: true,
                                short_name: element.short_name
                            }]);
                        } 
                    })
                } 
            })
            
            if(this.days.length == 0){
                this.days = this.days.concat(this.sessionForm.week);
            }
        } 
    }
    
    initializeForm(){
        this.myForm = this.formBuilder.group({})

    }

    setTimeOfDays(event: Array<any>) {
        const day_aux = this.days;
        this.days = [];       
        
        event.forEach((day)=>{
            const find_day = day_aux.find(element=> element.short_name === day.short_name)
            if (find_day && find_day.schedules) {
                day.schedules = find_day.schedules;
            } else {
                day.schedules = [{ start: '09:00', end: '17:00' }];

            }

            this.days.push(day)
        })

        this.days = this.days.sort((a:any, b:any)=> a.numberDay - b.numberDay);
        if(event > day_aux){
            setTimeout(()=>{
                document.getElementById('schedule-day').scrollIntoView({behavior:'smooth'});
            }, 100)
        }
    }
    
    addScheduleDay(day: any) {
        day.schedules.push({ start: '18:00', end: '23:00' });
        setTimeout(()=>{
            document.getElementById('schedule-day').scrollIntoView({behavior:'smooth'});
        }, 100)
    }
    
    removeScheduleDay(day: any, indexShedule: number) {
        if(day.schedules.length == 1){
            day.selected = false
        } else {
            day.schedules.splice(indexShedule, 1);
        }
    }

    replicateDays(time, name){
        const day_aux = this.days;
        this.mixPanelService.sendEvent('click_fe_schedule_replicate_for_everyday')
        time.forEach((day)=>{
            const find_day = day_aux.find(element=> element.name == name)
            let schedule_day = [];
            find_day.schedules.forEach((day)=>{
                schedule_day.push({...day}) 
            })

            if(find_day){
                this.days = [];

                this.weekDays.forEach((week_day)=>{
                    this.days.push({
                        name: week_day.name,
                        schedules: [...schedule_day],
                        selected: true,
                        short_name: week_day.short_name
                    });
                })
            }
        })

        setTimeout(()=>{
            document.getElementById('last-window').scrollTo({top: document.documentElement.scrollHeight, behavior:'smooth'})
        }, 100)
    }

    returnPage(){
        window.history.back()
    }
    
    createForm(){
        const verify_day = this.days.find(day => day.selected && day.schedules.length != 0)

        if(verify_day){
            if(Object.keys(this.myForm.controls).includes('week')){
                this.myForm.controls.week.setValue(this.days.filter(day => day.selected && day.schedules.length != 0))
            } else{
                this.myForm.addControl('week', new UntypedFormControl(this.days.filter(day => day.selected && day.schedules.length != 0)) )
            }
            
            if(this.myForm.valid){
                const form = {
                    week: this.myForm.value?.week || []
                }

                const days_selected = form.week.map((day)=>{
                    return day.name
                })

                this.sessionStorageService.updateForm(form);
                this.mixPanelService.sendEvent('click_fe_schedule_filled',{days_selected: days_selected})
                this.router.navigate(['delivery-informations/' + this.pageId]);
            }
        } else {
            this.days = []
            this.anotaToastService.setShowToast({
                type: 'danger',
                message: 'Preencha os campos corretamente!'
            });
        }
    }

}