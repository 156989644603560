import { Injectable } from '@angular/core';
import * as amplitude from '@amplitude/analytics-browser';

const API_KEY = location.hostname === "localhost" || location.hostname.includes("preprod") || location.hostname.includes("staging") ? "b1de99c089cd24c0a85deff25a9e7387" : "f1438bd5b9f1f8488a367f80911d2984";

@Injectable({ providedIn: 'root' })
export class AmplitudeAnalyticsService {

  constructor( ) {}

  public initializeAmplitudeAnalytics() {
    amplitude.init(API_KEY, {
      defaultTracking: {
        pageViews: false
      }
    });
  }

  public setUserId(id: string) {
    amplitude.setUserId(id);
  }
}