import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormGroupDirective, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { FooterComponent } from './components/footer/footer.component'

const DECLARATIONS_MODULES = [
	FooterComponent
]
const IMPORTS_MODULES = [CommonModule, RouterModule, ReactiveFormsModule]

@NgModule({
	declarations: [...DECLARATIONS_MODULES],
	imports: [...IMPORTS_MODULES],
	providers: [FormGroupDirective],
	exports: [...DECLARATIONS_MODULES, ...IMPORTS_MODULES]
})
export class SharedModule {}
