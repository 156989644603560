import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterForm } from "src/app/shared/interfaces/register-form";
import { AnotaToastService } from "../shared/services/ds-services/anota-toast.service";
import { RegisterService } from "../shared/services/register-service/register.service";
import { CpfCnpjValidator } from "../shared/validators/cpf-cnpj.validator";
import { MixPanelService } from "../shared/services/mix-panel/mix-panel.service";
import { AnotaLoaderService } from "../shared/services/ds-services/anota-loader.service";
import { SessionStorageService } from "../shared/services/session-storage/session-storage.service";


@Component({
    selector: 'app-payment-informations',
    templateUrl: 'payment-informations.component.html',
    styleUrls: ['payment-informations.component.scss']
})
export class PaymentInformationsComponent implements OnInit {
    public myForm: UntypedFormGroup;
    public modalFormPix: UntypedFormGroup;
    public pageId;
    public sessionForm;
    public registerForm: RegisterForm = {};
    public paymentList: Array<any> = [];
    public creditCards: Array<any> = [];
    public messageError = '';
    public visibleModal = false;
    public verifyPix = false;
    public regexFormat = '';
    public loading: boolean = false
    public step: number = 3;
    

    cardList = new Map ([
        [1, {option: 'VISA', checked: false}],
        [2, {option: 'VISA ELECTRON', checked: false}],
        [3, {option: 'MASTERCARD', checked: false}],
        [4, {option: 'DÉBITO MASTERCARD', checked: false}],
        [5, {option: 'MASTERCARD MAESTRO', checked: false}],
        [6, {option: 'ELO', checked: false}],
        [7, {option: 'ELO DÉBITO', checked: false}],
        [8, {option: 'HIPERCARD', checked: false}],
        [9, {option: 'BANESCARD', checked: false}],
        [10, {option: 'CABAL', checked: false}],
        [11, {option: 'DINERS', checked: false}],
    ])

    keyList = [
        {label: 'CPF', value: '1', mask: 'cpf'},
        {label: 'CNPJ', value: '2', mask: 'cnpj'},
        {label: 'E-mail', value: '3', mask:''},
        {label: 'Celular', value: '4', mask:'cel'},
        {label: 'Chave Aleatória', value: '5', mask:''},
    ]

    constructor(
        private registerService: RegisterService,
        private mixPanelService: MixPanelService,
        private sessionStorageService: SessionStorageService,
        private anotaToastService: AnotaToastService,
        private anotaLoaderService: AnotaLoaderService,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
		private route: ActivatedRoute,
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.sessionStorageService.pageId = this.pageId;
        this.sessionForm = this.registerService.getform();
        this.sessionForm?.cpf_cnpj?.length == 11 ? this.keyList.splice(1,1) : this.keyList.splice(0,1)
    }
    
    ngOnInit(){
        this.initPix();
        if(this.sessionForm.credit_cards && this.sessionForm.credit_cards != 0){
            this.sessionForm.credit_cards.forEach((element:any)=>{
                if(element.name){
                    this.creditCards = this.creditCards.concat([{
                        option: element.name,
                        checked: true
                    }]);
                } else if (element.option){
                    this.creditCards = this.creditCards.concat([{
                        option: element.option,
                        checked: true
                    }]);
                    
                }
            })

            if(this.sessionForm?.payment_method){
                this.sessionForm.payment_method.forEach((element)=>{
                    if(element.name == 'Pix Automatizado'){
                        this.paymentList = this.paymentList.concat({name: element.name, value: element.value, description: 'Receba pix com segurança'})
                    } else {
                        this.paymentList = this.paymentList.concat({name: element.name, value: element.value,})
                    }
                })
            } else {
                this.paymentList = [
                    {name: 'Dinheiro', value: true},
                    {name: 'Cartão', value: true},
                    {name: 'Pix Automatizado', value: false, description: 'Receba pix com segurança'},
                ]
            }
            
            
            this.sessionForm.credit_cards = this.creditCards;
            this.initializeForm()
            this.initOptionCards();
        } else {
            this.paymentList = [
                {name: 'Dinheiro', value: false},
                {name: 'Cartão', value: false},
                {name: 'Pix Automatizado', value: false, description: 'Receba pix com segurança'},
            ]

            this.initializeForm();
            this.initOptionCards();
        }

    }
    
    initializeForm(){
        this.myForm = this.formBuilder.group({})
    }

    initPix(){
        this.modalFormPix = this.formBuilder.group({
            key_pix:['', [Validators.required]],
            type_key:['', [Validators.required]],
            cpf_cnpj: [{ value:this.sessionForm?.cpf_cnpj || '', disabled: true}, [
                Validators.required,
                CpfCnpjValidator.validCpfCnpj()
            ]],
            company_name: [{value: this.sessionForm?.company_name || '', disabled: true}, [Validators.required]],
            fantasy_name: [{value: this.sessionForm?.fantasy_name || '', disabled: true}, [Validators.required]],
            name: [{value: this.sessionForm?.name || '', disabled: true}, [Validators.required]],
            email: [this.sessionForm?.email || '', [Validators.required]]
        });
        
        this.regexFormat = this.sessionForm?.cpf_cnpj
        this.maskCpfCnpj();
        this.modalFormPix.controls.type_key.valueChanges.subscribe((res)=>{this.checkPixKey(res.label, this.sessionForm.cpf_cnpj)})
    }

    maskCpfCnpj(){
        if(this.regexFormat){
            if( this.regexFormat.length == 11){
                this.regexFormat = this.regexFormat.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, 
                function( regex, argumento1, argumento2, argumento3, argumento4 ) {
                    return argumento1 + '.' + argumento2 + '.' + argumento3 + '-' + argumento4;
                })
            } else {
                this.regexFormat = this.regexFormat.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, 
                function( regex, argumento1, argumento2, argumento3, argumento4, argumento5 ) {
                    return argumento1 + '.' + argumento2 + '.' + argumento3 + '/' + argumento4 + '-' + argumento5;
                })
            }
        }
    }

    checkPixKey(event, session){
        if(event == 'CPF' && session.length == 11){
            setTimeout(()=>{
                this.modalFormPix.controls.key_pix.setValue(session)
            }, 100)
        } 
        else if (event == 'CNPJ' && session.length == 14){
            setTimeout(()=>{
                this.modalFormPix.controls.key_pix.setValue(session)
            }, 100)
        } else {
            this.modalFormPix.controls.key_pix.setValue('')
        }
    }

    initOptionCards() {
        for (let i = 1; i <= this.cardList.size; i++) {
            const card = {...this.cardList.get(i)};
            if(this.sessionForm?.credit_cards){
                if (!(this.sessionForm?.credit_cards?.find((element) => element.option.toLowerCase() === card.option.toLowerCase()))){
                    this.creditCards.push(card);
                }
            } else {
                card.checked = true;
                this.creditCards.push(card);
            }
        }
    }

    returnPage(){
        window.history.back()
    }

    setCard(card){
        if(this.sessionForm?.credit_cards){
            for (let i = 0; i <= this.creditCards.length; i++) {
                const credit = {...this.creditCards[i]};
                if(credit.option == card.option){
                    this.creditCards[i].checked == false ? this.creditCards[i].checked = true : this.creditCards[i].checked = false
                }
            }
        } else {
            for (let i = 0; i <= this.cardList.size; i++) {
                const credit = {...this.cardList.get(i)};
                if(credit.option == card.option){
                    this.creditCards[i-1].checked == false ? this.creditCards[i-1].checked = true : this.creditCards[i-1].checked = false
                }
            }
        }

    }

    showModal(){
        document.getElementById('pix-show').scrollIntoView({behavior:'smooth'});
        if(this.sessionForm.pixResponse){
            this.visibleModal = false;
        } else {
            this.visibleModal = true;
        }
    }

    registerVerify(event){
        this.verifyPix = event;
    }

    cancelModal(){
        this.paymentList.find((element)=>{
            if(element.description){
                element.value = false;
            }
        })

        this.visibleModal = false;
    }

    confirmPix(){
        this.loading = true;
        this.anotaLoaderService.setShowLoader(this.loading);
        this.mixPanelService.sendEvent('click_fe_payment_methods_pix_registered')
        if(this.modalFormPix.valid){
            const formPix = {
                ResponsibleName: this.modalFormPix.controls.name.value,
                IdentityCompany: this.modalFormPix.controls.cpf_cnpj.value,
                pixKey: this.modalFormPix.controls.key_pix.value,
                pixKeyType: this.modalFormPix.controls.type_key.value.value,
                Name: this.modalFormPix.controls.company_name.value,
                CommercialName: this.modalFormPix.controls.fantasy_name.value,
                Email: this.modalFormPix.controls.email.value,
                page_id: this.pageId,
                user_id: this.sessionForm?.user_id
            }

            this.registerService.changePix(formPix).subscribe((res:any)=>{
                if(res.success){
                    this.sessionForm.pixResponse = true
                    this.sessionStorageService.updateForm(this.sessionForm.pixResponse);
                    this.loading = false;
                    this.anotaLoaderService.setShowLoader(this.loading);
                    this.anotaToastService.setShowToast({
                        type: 'success',
                        message: 'Cadastro enviado com sucesso!'
                    });
                } else {
                    this.loading = false;
                    this.anotaLoaderService.setShowLoader(this.loading);
                    this.anotaToastService.setShowToast({
                        type: 'danger',
                        message: res.message
                    });
                }
            }, (err)=>{
                this.loading = false;
                this.anotaLoaderService.setShowLoader(this.loading);
                this.anotaToastService.setShowToast({
                    type: 'danger',
                    message: 'OPS! Estamos com algum problema interno :('
                });
                console.log(err)
            })
            
        } else {
            this.loading = false;
            this.anotaLoaderService.setShowLoader(this.loading);
            this.verifyPix = true;
            this.anotaToastService.setShowToast({
                type: 'danger',
                message: 'Preencha os dados corretamente!'
            });
        }
    }
    
    createForm(){

        if(Object.keys(this.myForm.controls).includes('payment_method')){
            this.myForm.controls.payment_method.setValue(this.paymentList.filter(payment => payment))
        } else{
            this.myForm.addControl('payment_method', new UntypedFormControl(this.paymentList.filter(payment => payment)) )
        }

        if(Object.keys(this.myForm.controls).includes('credit_cards')){
            this.myForm.controls.credit_cards.setValue(this.creditCards.filter(credit => credit.checked == true))
        } else{
            this.myForm.addControl('credit_cards', new UntypedFormControl(this.creditCards.filter(credit => credit.checked == true)) )
        }

        const form = {
            payment_method: this.myForm.value?.payment_method,
            credit_cards: this.myForm.value?.credit_cards
        }
        
        let verify_method = form.payment_method.filter(element => element.value == true);

        
        let verify_cards = form.credit_cards.find(element => element);
        let verify_method_card = form.payment_method.find(element => element.name == 'Cartão');
        
        if(verify_method_card.value == true){
            if(verify_cards != undefined){
                const methods_selected = verify_method.map((method)=>{
                    return method.name
                })
        
                const card_flags = form.credit_cards.map((card) => {
                    return card.option
                })
        
                const pix_type = this.modalFormPix.controls.type_key.value.label
        
                this.mixPanelService.sendEvent('click_fe_payment_methods', {methods_selected, card_flags, pix_type})        
                this.sessionStorageService.updateForm(form);
                this.router.navigate(['establishment/menu/' + this.pageId]);
            } else{
                this.anotaToastService.setShowToast({
                    type: 'danger',
                    message: 'Informe as bandeiras de cartão!'
                });
            }
        } else if(verify_method.length != 0){
            form.credit_cards = [];
            const methods_selected = verify_method.map((method)=>{
                return method.name
            })
    
            const card_flags = form.credit_cards.map((card) => {
                return card.option
            })
    
            const pix_type = this.modalFormPix.controls.type_key.value.label
    
            this.mixPanelService.sendEvent('click_fe_payment_methods', {methods_selected, card_flags, pix_type})
            this.sessionStorageService.updateForm(form);
            this.router.navigate(['establishment/menu/' + this.pageId]);
        } else {
            this.anotaToastService.setShowToast({
                type: 'danger',
                message: 'Preencha as formas de pagamento!'
            });
        }
    }

}