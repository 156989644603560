import { Injectable } from '@angular/core';
import { Experiment, ExperimentClient, Variant } from '@amplitude/experiment-js-client';
import { environment } from 'src/environments/environment';

const DEPLOYMENT_KEY = environment.AMPLITUDE_KEY;

@Injectable({ providedIn: 'root' })
export class AmplitudeExperimentService {
  experiment: ExperimentClient;

  constructor() {}

  async initializeAmplitudeExperiment(pageId: string) {
    if (this.getExperimentUser()?.user_id) {
      return;
    }

    this.experiment = Experiment.initializeWithAmplitudeAnalytics(DEPLOYMENT_KEY);
    
    const user = {
      user_id: pageId
    }

    try {
      await this.experiment.start(user);
    } catch (error) {
      console.log(error);
    }
  }

  private getExperimentUser() {
    return this.experiment?.getUser();
  }

  async getVariantByExperimentKey(key: string) {
    return this.experiment?.variant(key);
  }
}