import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterForm } from "src/app/shared/interfaces/register-form";
import { RegisterService } from "src/app/shared/services/register-service/register.service";
import { CpfCnpjValidator } from "src/app/shared/validators/cpf-cnpj.validator";
import { EstablishmentService } from "../establishment.service";

@Component({
    selector: 'app-establishment-part-1',
    templateUrl: 'establishment-part-1.component.html',
    styleUrls: ['establishment-part-1.component.scss']
})
export class EstablishmentPart1Component implements OnInit {
    public myForm: UntypedFormGroup;
    public pageId;
    public sessionForm;
    public registerForm: RegisterForm = {};
    public file: any;
    public listSegment: Array<any> = [];

    constructor(
        private registerService: RegisterService,
        private establishmentService: EstablishmentService,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
		private route: ActivatedRoute,
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.registerService.pageId = this.pageId;
        this.sessionForm = this.registerService.getform();
    }
    
    ngOnInit(){
        this.initializeForm();
    }

    initializeForm(){

        this.myForm = this.formBuilder.group({
            name: [this.sessionForm?.name || '', [Validators.required]],
            company_name: [this.sessionForm?.company_name || '', [Validators.required]],
            fantasy_name: [this.sessionForm?.fantasy_name || '', [Validators.required]],
            cpf_cnpj: [this.sessionForm?.cpf_cnpj || '', [
                Validators.required,
                CpfCnpjValidator.validCpfCnpj()
            ]]
        })

    }

    returnPage(){
        window.history.back()
    }

    createForm(){
        if(this.myForm.valid){
            const form = {
                name: this.myForm.value?.name,
                company_name: this.myForm.value?.company_name,
                fantasy_name: this.myForm.value?.fantasy_name,
                cpf_cnpj: this.myForm.value?.cpf_cnpj
            }

            if(this.sessionForm?.final_informations){
                this.establishmentService.updateForm(form);
                this.router.navigate(['final-informations/' + this.pageId]);
            } else {
                this.establishmentService.updateForm(form)
                this.router.navigate(['establishment/establishment-part-2/' + this.pageId])
            }
        }
    }
}