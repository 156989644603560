<div id='last-window' class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/logo.svg" style="height: 50px;" alt="anotaai">
        </div>

        <div class="steps-container">
            <div class="progress-bg">
              <div class="bar" style="--progress-bar: {{step}}"></div>
            </div>
            <div class="step" [ngClass]="{'active': step == 0, 'checked': step > 0}">
              <div class="icon">
                <anota-icon iconName="clock"></anota-icon>
              </div>
            <!-- <span class="text">Dados</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step == 1, 'checked': step > 1}">
              <div class="icon">
                <anota-icon iconName="deliverymen" iconStroke="1.3"></anota-icon>
              </div>
              <!-- <span class="text">Responsável</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step >= 2}" [ngClass]="{'active': step == 2, 'checked': step > 2}">
              <div class="icon">
                <anota-icon iconName="map-pin" iconStroke="1.3"></anota-icon>
              </div>
              <!-- <span class="text">Dados</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step === 3}" [ngClass]="{'active': step == 3, 'checked': step > 3}">
              <div class="icon">
                <anota-icon iconName="dollar-sign" iconStroke="1.3"></anota-icon>
              </div>
              <!-- <span class="text">Endereço</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step === 4}" [ngClass]="{'active': step == 4, 'checked': step > 4}">
                <div class="icon">
                  <anota-icon iconName="image" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Teste</span> -->
              </div>
          </div>
        <br>

        <div class="title-container">
            <p>Horário de funcionamento</p>
        </div>
        <div class="subtitle-container">
            <p>Selecione os dias da semana e preencha o horário de funcionamento do seu estabelecimento</p>
        </div>
        <div class="input-container">
            <div>
                <div class="container-time">
                  <div class="day-picker">
                    <anota-day-picker (emitChange)="setTimeOfDays($event)" label="Dias da semana *" [selectedDays]="days"></anota-day-picker>
                  </div>
                  <div *ngFor="let day of days;" >
                      <div *ngIf="day.selected">
                          <span>{{ day.name }} *</span>
                          <br><br>
                          <div  class="set-time-day" *ngFor="let schedule of day.schedules; let i = index">
                              <anota-input-time id="schedule-day" icon="clock" searchPosition="left" [(ngModel)]="schedule.start"></anota-input-time>
                              <div><span>às</span></div>
                              <anota-input-time icon="clock" searchPosition="left" [(ngModel)]="schedule.end"></anota-input-time>
                              
                              <anota-button type="link" size="sm" *ngIf="i === day.schedules.length - 1" 
                              (click)="addScheduleDay(day)">
                                <anota-icon iconName="plus-circle"></anota-icon>
                                Adicionar
                              </anota-button>
                              
                              <anota-button type="link" size="sm" *ngIf="i !== day.schedules.length - 1"
                              (click)="removeScheduleDay(day, i)">
                              <anota-icon iconName="trash-2"></anota-icon>
                                Remover
                              </anota-button>
                          </div>
                          <div *ngFor="let schedule of day.schedules; let j = index" class='reply-button'>
                              <anota-button 
                                  *ngIf="(days.length - 1 == 0 && days.length - 1 == j)" 
                                  (click)="replicateDays(day.schedules, day.name)"
                                  size="sm" 
                              >Repetir horário para todos</anota-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    <anota-footer [noSidebar]="true" [noMenuFooter]="true" class="footer">
        <div class="button">
            <anota-button size="lg" type="outline-primary" (click)="returnPage()">Anterior</anota-button>
            <anota-button size="lg" (click)="createForm()" [disabled]="!days">Próximo</anota-button>
        </div>
    </anota-footer>
</div>
