import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AmplitudeExperimentService } from '../services/amplitude/amplitude-experiment.service';
import { environment } from 'src/environments/environment';
import { AmplitudeAnalyticsService } from '../services/amplitude/amplitude-analytics.service';
import { AnotaLoaderService } from '../services/ds-services/anota-loader.service';

const variantUrl = environment.VARIANT_B

@Injectable({
  providedIn: 'root'
})
export class RouterGuard  {
  public ABTEST_variant;
  public pageId;

  constructor(
    private amplitudeAnalyticsService: AmplitudeAnalyticsService,
    private amplitudeExperimentService: AmplitudeExperimentService,
    private router: Router,
    private anotaLoaderService: AnotaLoaderService,
  ){
    let location = window.location.href.split('?')[0].split('/');
		this.pageId = location[location.length-1];
    this.amplitudeAnalyticsService.setUserId(this.pageId);
  }
  
  async canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    this.anotaLoaderService.setShowLoader(true);
    await this.amplitudeExperimentService.initializeAmplitudeExperiment(this.pageId)
    this.ABTEST_variant = (await this.amplitudeExperimentService.getVariantByExperimentKey('onboarding_register_garcom')).value
    
    if(this.ABTEST_variant === 'variant_b'){
      window.location.href = `${variantUrl}/${this.pageId}`
    } else {
      this.anotaLoaderService.setShowLoader(false);
    }

    return true;
  }
  
}
