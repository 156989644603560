import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { RegisterForm } from "../../interfaces/register-form";
import { SessionStorageService } from "../session-storage/session-storage.service";

@Injectable({
    providedIn: 'root'
})
export class RegisterService {
	public backend_url = environment.BACKEND_URL;
	public admin_url = environment.ADMIN_URL;
	public pageId;
	public form;
	public formPayload = {};
	public db: any;
	public count = 0;
	public verify_res = false;
	public token = localStorage.getItem('token');
	public home_detection : boolean = false;

    constructor(
		private http: HttpClient,
		private router: Router,
		private route: ActivatedRoute,
		private sessionStorageService: SessionStorageService
	){
		let location = window.location.href.split('?')[0].split('/');
		this.pageId = location[location.length-1]

		if(this.verify_res == false){
			this.getData().subscribe((res:any)=>{
				this.verify_res = true;
				if(res.data?.token){
					localStorage.setItem('token', res.data.token );
				}

				if(res.data?.filled_onboarding_register){
					this.router.navigate(["/error"]);
				}
				let form = {
					address: res.data?.address,
					name: res.data?.name,
					company_name: res.data?.establishment_name,
					fantasy_name: res.data?.fantasy_name,
					phone: res.data?.phone,
					email: res.data?.email,
					cpf_cnpj: res.data?.cpf_cnpj,
					credit_cards: res.data?.credit_cards,
					week: res.data?.week,
					regions: res.data?.regions,
					user_id: res.data?.user_id
				}
				
				this.getform();
				this.updateForm(form);
				
			}, (err)=>{
				console.log(err)
			})
		}
		
	}

    getform(): RegisterForm {
		
		let stored = localStorage.getItem("form");
		this.sessionStorageService.form = JSON.parse(stored);
		if(this.sessionStorageService.form == null){
			return this.sessionStorageService.form = {}
		} else {
			return this.sessionStorageService.form
		}

	}
	
	updateForm(data: object) {
		this.sessionStorageService.form = Object.assign(this.sessionStorageService.form, data);
		localStorage.setItem('form', JSON.stringify(this.sessionStorageService.form));
	}
	
	getData(){
		let url = `${this.backend_url}noauth/customer-register/${this.pageId}`;

		return this.http.get(url)
	}
			

	setForm(event){
		let url = `${this.backend_url}noauth/customer-register/send/${this.pageId}`;

		return this.http.post(url, event)
	}

	changePix(event){
		let url = `${this.backend_url}noauth/customer-register/payment`; 
		return this.http.post(url, event)
	}

	directsAdmin(){
		if(this.token){
			window.open(`${this.admin_url}?token=${this.token}`, '_blank')
			return 
		}
		
		window.open(`${this.admin_url}`, '_blank')
		// console.log(this.pageId)
	}


	addMenu(event){
		let url = `${this.backend_url}noauth/customer-register/upload-image/${this.pageId}`
		
		return this.http.post(url, event)
	}

	deleteMenu(event){
		let url = `${this.backend_url}noauth/customer-register/delete-image`
		return this.http.post(url, event)
	}

	// getAllSegments() {
	// 	const url = `${this.url}/auth/establishment/segments/get-all-segments`;
	// 	return this.http.get(url);
	// }

}