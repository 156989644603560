import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterForm } from "src/app/shared/interfaces/register-form";
import { AnotaToastService } from "src/app/shared/services/ds-services/anota-toast.service";
import { RegisterService } from "src/app/shared/services/register-service/register.service";
import { EstablishmentService } from "../establishment.service";


@Component({
    selector: 'app-pizza',
    templateUrl: 'pizza.component.html',
    styleUrls: ['pizza.component.scss']
})
export class PizzaComponent implements OnInit {
    public myForm: UntypedFormGroup;
    public pageId;
    public sessionForm;
    public registerForm: RegisterForm = {};
    public pizza: Array <any> = [];
    public messageError = '';

    sizePizzaMap = new Map([
        [1, {
            title: "Broto",
            amount_slice: '',
            amount_flavor: '',
            selected: false,
        }],
        [2, {
            title: "Pequena",
            amount_slice: '',
            amount_flavor: '',
            selected: false,
        }],
        [3, {
            title: "Média",
            amount_slice: '',
            amount_flavor: '',
            selected: false,
        }],
        [4, {
            title: "Grande",
            amount_slice: '',
            amount_flavor: '',
            selected: false,
        }],
        [5, {
            title: "Gigante",
            amount_slice: '',
            amount_flavor: '',
            selected: false,
        }],
        [6, {
            title: "Familia",
            amount_slice: '',
            amount_flavor: '',
            selected: false,
        }],
    ])

    constructor(
        private registerService: RegisterService,
        private establishmentService: EstablishmentService,
        private anotaToastService: AnotaToastService,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
		private route: ActivatedRoute,
    ){
        this.pageId = this.route.snapshot.paramMap.get('id');
        this.establishmentService.pageId = this.pageId;
        this.sessionForm = this.registerService.getform();
    }
    
    ngOnInit(){
        this.initializeForm();
        if(this.sessionForm.sizes && this.sessionForm.sizes.length != 0){
            this.sizePizzaMap.forEach((element)=>{
                this.sessionForm.sizes.forEach((sizes)=>{
                    if(element.title == sizes.title){
                        element.selected = true;
                        element.amount_flavor = sizes.amount_flavor;
                        element.amount_slice = sizes.amount_slice
                    }
                })
            })

            this.initOptionPizza()
        } else {
            this.initOptionPizza()
        }
    }

    initializeForm(){
        this.myForm = this.formBuilder.group({})
    }

    initOptionPizza() {
        if(!this.sessionForm.sizes || this.sessionForm.sizes.length == 0){
            this.pizza = [];

            for (let i = 1; i <= this.sizePizzaMap.size; i++) {
                const pizza = {...this.sizePizzaMap.get(i)};
                pizza.selected = false;
                this.pizza.push(pizza);
            }
        } else {
            for (let i = 1; i <= this.sizePizzaMap.size; i++) {
                const pizza = {...this.sizePizzaMap.get(i)};
                this.pizza.push(pizza);
            }
        }
    }


    returnPage(){
        window.history.back()
    }

    createForm(){ 
        const size_pizza = this.pizza.filter(element => element.selected == true);
        const verify_size = size_pizza.filter(element => element.amount_slice != '' && element.amount_flavor != '')

        if(verify_size.length != 0){
            this.messageError= ''
            if(Object.keys(this.myForm.controls).includes('sizes')){
                this.myForm.controls.sizes.setValue(size_pizza);
            } else{
                this.myForm.addControl('sizes', new UntypedFormControl(size_pizza));
            }
    
            if(this.myForm.valid){
                const form = {
                    sizes: this.myForm.value?.sizes
                }
                
                if(this.sessionForm?.final_informations){
                    this.establishmentService.updateForm(form);
                    this.router.navigate(['final-informations/' + this.pageId]);
                } else {
                    this.establishmentService.updateForm(form); 
                    this.router.navigate(['establishment/pizza-part-3/' + this.pageId]);
                }
            }
        } else {
            this.anotaToastService.setShowToast({
                type: 'danger',
                message: 'Preencha os campos corretamente!'
            });
        }
    }
}