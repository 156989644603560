<div class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/logo.svg" style="height: 50px;" alt="anotaai">
        </div>

        <div class="steps-container">
            <div class="progress-bg">
                <div class="bar" style="--progress-bar: {{step}}"></div>
            </div>
            <div class="step" [ngClass]="{'active': step == 0, 'checked': step > 0}">
                <div class="icon">
                <anota-icon iconName="clock"></anota-icon>
                </div>
            <!-- <span class="text">Dados</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step == 1, 'checked': step > 1}">
                <div class="icon">
                <anota-icon iconName="deliverymen" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Responsável</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step >= 2}" [ngClass]="{'active': step == 2, 'checked': step > 2}">
                <div class="icon">
                <anota-icon iconName="map-pin" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Dados</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step === 3}" [ngClass]="{'active': step == 3, 'checked': step > 3}">
                <div class="icon">
                <anota-icon iconName="dollar-sign" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Endereço</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step === 4}" [ngClass]="{'active': step == 4, 'checked': step > 4}">
                <div class="icon">
                    <anota-icon iconName="image" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Teste</span> -->
                </div>
            </div>
        <br>

        <div class="title-container">
            <p>Formas de pagamento aceitas</p>
        </div>
        <div class="subtitle-container">
            <p>Selecione as opções que melhor representam as formas de pagamento aceitas pelo seu estabelecimento.</p>
        </div>
        <div class="input-container">
            <div *ngFor="let payment of paymentList">
                <anota-checkbox 
                    [(ngModel)]="payment.value"
                    [helperText]="payment.description"
                    [checked]="false"
                    (click)="payment.description && payment.value == true ? showModal() : ''"
                >
                {{payment.name}}
                </anota-checkbox>
                <div *ngIf="(payment?.description && payment?.value == true) && !sessionForm.pixResponse">
                    <div class="pix">
                        <div class="banner-pix">
                            <p class="text">
                                Com o <b>Pix Automatizado</b> você não precisa se preocupar em conferir nenhum comprovante, nosso robô fará tudo para você!
                                Taxa de somente R$0,50 por transação e você terá o valor repassado em apenas 1 dia útil. <b>Bora automatizar?</b>
                            </p>
                        </div>
                        <form [formGroup]="modalFormPix" novalidate>
                            <div class="pix-warning">
                                <span>
                                    Esta chave PIX deve pertencer a conta bancária com o CPF/CNPJ 
                                    <p>{{ regexFormat }}</p>
                                </span>
                            </div>
                            <div id="pix-show" class="pix-tags">
                                <anota-select
                                size="lg"
                                label="Tipo de Chave *"
                                [showSearch]="false"
                                formControlName="type_key"
                                placeholder="Selecione a Chave Pix"
                                [options]="keyList"
                                ></anota-select>
                                <anota-input-text 
                                    size="lg"
                                    label="Chave Pix *"
                                    placeholder="Responda aqui"
                                    formControlName="key_pix"
                                    [mask]="modalFormPix.value.type_key.mask"
                                ></anota-input-text>
                            </div>
                            <br>
                            <div class="pix-warning-two">
                                <span>OBS: A CHAVE PIX cadastrada acima será utilizada para os repasses de todas as transações do seu estabelecimento.</span>
                            </div>
                            <div class="button">
                                <!-- <anota-button size="lg" type="outline-primary" (click)="returnPage()">Anterior</anota-button> -->
                                <anota-button size="md" (click)="confirmPix()">Cadastrar Pix</anota-button>
                            </div>
                        </form>

                    </div>
                </div>
                <!--  -->
                <div class="pix" *ngIf="(payment?.description && payment?.value == true) && sessionForm.pixResponse">
                    <div class="banner-pix">
                        <p class="text">
                            Com o <b>Pix Automatizado</b> você não precisa se preocupar em conferir nenhum comprovante, nosso robô fará tudo para você!
                            Taxa de somente R$0,50 por transação e você terá o valor repassado em apenas 1 dia útil. <b>Bora automatizar?</b>
                        </p>
                    </div>
                    <div id="pix-show" class="pix-card">
                        <div style="width: auto; display: flex; align-items: center; justify-content: center;">
                            <img src="../../assets/Group.svg" style="height: 80px;" alt="anotaai">
                        </div>
                        <br>
                        <div class="pix-card-title">
                            <p>Cadastro do Pix enviado com sucesso!</p>
                        </div>
                        <p>
                            Em breve você receberá um e-mail que te dá acesso à plataforma <a href="https://www.tuna.uy/" target="_blank">Tuna</a>, responsável pelas transações. 
                            Seu cadastro será aprovado em até 24 horas.
                        </p>
                    </div>
                </div>
                <br>
                <div class="container-credit" *ngIf="payment.name == 'Cartão' && payment.value == true">
                    <div  *ngFor="let credit of creditCards">
                        <anota-chips 
                        [active]="credit.checked"
                        (click)="setCard(credit)"
                        >
                        {{credit.option}}
                        </anota-chips>
                        <br>
                    </div>
                </div>
            </div>
        </div>
        <br><br>
    </div>
    <anota-footer [noSidebar]="true" [noMenuFooter]="true" height="3.2rem" class="footer">
        <div class="button">
            <anota-button size="lg" type="outline-primary" (click)="returnPage()">Anterior</anota-button>
            <anota-button size="lg" (click)="createForm()">Próximo</anota-button>
        </div>
    </anota-footer>
</div>

<!-- <anota-modal 
    type="center" 
    anotaTitle="Cadastre sua chave para receber via Pix"
    [noButton]="false"
    [visible]="visibleModal"
    (confirmModal)="confirmModal()"
    alignButtons="center"
    width="900px"
    height="auto"
    (visibleChange)="cancelModal()"
    >
    <form [formGroup]="modalFormPix" novalidate>
        <div class="modal">
            <div class="modal-warning">
                <span>
                    Esta chave PIX deve pertencer a conta bancária com o CPF/CNPJ 
                    <p>{{ regexFormat }}</p>
                </span>
            </div>
            <div *ngIf="verifyPix" class="modal-title">
                <span>Dados Bancários</span>
            </div>
            <div class="modal-tags">
                <anota-select
                size="lg"
                label="Tipo de Chave *"
                [showSearch]="false"
                formControlName="type_key"
                placeholder="Selecione a Chave Pix"
                [options]="keyList"
                ></anota-select>
                <anota-input-text 
                    size="lg"
                    label="Chave Pix *"
                    placeholder="Responda aqui"
                    formControlName="key_pix"
                    [mask]="modalFormPix.value.type_key.mask"
                ></anota-input-text>
            </div>
            <br>
            <div class="modal-warning-two">
                <span>A Chave Pix cadastrada acima será utilizada para os repasses de todas as transações do seu estabelecimento. Será cobrada uma taxa de R$0,50 (cinquenta centavos) por transação realizada e os repasses ocorrerão em até 1 dia útil.</span>
            </div>
            <div *ngIf="!verifyPix" class="modal-div">
                <span style="color: #747272; font-size: 15px;">Outros dados do cadastro do PIX foram coletados antes.</span>
                <span (click)="registerVerify(true)" style="color: #0098fc; font-size: 15px; text-decoration: underline; cursor: pointer;">Ver todas as informações de cadastro</span>
            </div>
        </div>
        <div *ngIf="verifyPix" class="modal">
            <br>
            <div class="modal-title">
                <span>Dados da Empresa</span>
            </div>
            <div class="cpf-input">
                <anota-input-text 
                size="lg"
                label="CPF/CNPJ *"
                placeholder="Responda aqui"
                formControlName="cpf_cnpj"
                mask="cpf_cnpj"
                ></anota-input-text>
            </div>
            <br>
            <div class="modal-tags">
                <anota-input-text 
                    size="lg"
                    label="Razão Social *"
                    placeholder="Responda aqui"
                    formControlName="company_name"
                ></anota-input-text>
                <anota-input-text 
                size="lg"
                label="Nome Fantasia *"
                placeholder="Responda aqui"
                formControlName="fantasy_name"
                ></anota-input-text>
            </div>
            <br><br>
        </div>
        <div *ngIf="verifyPix" class="modal">
            <div class="modal-title">
                <span>Dados do Responsável</span>
            </div>
            <div class="modal-tags">
                <anota-input-text 
                    size="lg"
                    label="Responsável Legal *"
                    placeholder="Responda aqui"
                    formControlName="name"
                ></anota-input-text>
                <anota-input-text 
                size="lg"
                label="E-mail *"
                placeholder="Responda aqui"
                formControlName="email"
                ></anota-input-text>
            </div>
            <br><br>
            <span style="color: #747272; font-size: 15px;">Os dados preenchidos no cadastro do pix, não afetarão as informações referentes ao formulário Anota Ai.</span>
            <br><br>
            <div *ngIf="verifyPix" class="modal-div">
                <span style="color: #747272; font-size: 15px;">Você pode utilizar o cadastro otimizado.</span>
                <span (click)="registerVerify(false)" style="color: #0098fc; font-size: 15px; text-decoration: underline; cursor: pointer;">Otimizar cadastro</span>
            </div>
        </div>
    </form>
</anota-modal> -->

<!-- <app-footer class="footer"></app-footer> -->