<div class="flow-home">
    <div class="container">
        <div class="img-container">
            <img src="../../assets/logo.svg" style="height: 50px;" alt="anotaai">
        </div>

        
        <div class="steps-container">
            <div class="progress-bg">
                <div class="bar" style="--progress-bar: {{step}}"></div>
            </div>
            <div class="step" [ngClass]="{'active': step == 0, 'checked': step > 0}">
                <div class="icon">
                <anota-icon iconName="clock"></anota-icon>
                </div>
            <!-- <span class="text">Dados</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step == 1, 'checked': step > 1}">
                <div class="icon">
                <anota-icon iconName="deliverymen" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Responsável</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step >= 2}" [ngClass]="{'active': step == 2, 'checked': step > 2}">
                <div class="icon">
                <anota-icon iconName="map-pin" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Dados</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step === 3}" [ngClass]="{'active': step == 3, 'checked': step > 3}">
                <div class="icon">
                <anota-icon iconName="dollar-sign" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Endereço</span> -->
            </div>
            <div class="step" [ngClass]="{'active': step === 4}" [ngClass]="{'active': step == 4, 'checked': step > 4}">
                <div class="icon">
                    <anota-icon iconName="image" iconStroke="1.3"></anota-icon>
                </div>
                <!-- <span class="text">Teste</span> -->
                </div>
            </div>
        <br>

        <div class="title-container">
            <p>Regiões de atendimento</p>
        </div>
        <div class="subtitle-container">
            <span>Cadastre o valor de entrega de acordo com a região de atendimento do seu estabelecimento</span>
        </div>
        <div class="input-container">
            <form [formGroup]="myForm" novalidate>
                <anota-radio-card-group align="column" formControlName="attendance_model">
                    <anota-radio-card size="md" value="Por Bairro">
                        Por Bairro
                    </anota-radio-card>
                    <div *ngIf="myForm.controls?.attendance_model.value == 'Por Bairro'">
                        <div *ngFor="let district of districtList" class="container-location">
                            <div class="set-location" *ngFor="let options of district.options; let i = index">
                                <input formControlName="attendance_district" style="display: none;">
                                <anota-input-text 
                                    id= 'input-district'
                                    [messageError]="messageError"
                                    label="Bairro *" 
                                    searchPosition="left" 
                                    placeholder="Ex.: Bairro Bom Jesus"
                                    [(ngModel)]="options.name"
                                    [ngModelOptions]="{standalone: true}"
                                    style="width: 70%;"
                                ></anota-input-text>
                                <span></span>
                                <anota-input-group
                                    [messageError]="messageError"
                                    icon="dollar-sign"  
                                    label="Valor" 
                                    mask="money" 
                                    searchPosition="left" 
                                    placeholder="R$ 0,00"
                                    [(ngModel)]="options.value"
                                    [ngModelOptions]="{standalone: true}"
                                ></anota-input-group>
                                <anota-button *ngIf="district.options.length > 1" icon="trash-2" type="link" size="sm" 
                                (click)="removeOptionDistrict(district, i)"></anota-button>
                                <anota-button icon="plus-circle" type="link" size="sm" [ngClass]="i == district.options.length - 1 ? '' : 'btn-add'"
                                (click)="addOptionDistrict(district, i)" style="justify-content: end !important;"></anota-button>
                            </div>
                        </div>
                    </div>
                    <!-- -->
                    <anota-radio-card [disabled]="!sessionForm.address"  size="md" value="Por Raio" (click) ="verifyMap(); filterValue()">
                        Por Raio
                    </anota-radio-card>
                    <div *ngIf="myForm.controls?.attendance_model.value == 'Por Raio'">
                        <div [ngStyle]="verifyRadius ? '' : {'display':'none'}" class="maps" #map></div>
                        <div *ngFor="let radius of radiusList" class="container-location">
                            <div class="set-location" *ngFor="let options of radius.options; let i = index">
                                <input formControlName="attendance_radius" style="display: none;">
                                <anota-input-text
                                    id= 'input-radius'
                                    [messageError]="messageError"
                                    label="Raio (Km) *" 
                                    searchPosition="left" 
                                    [placeholder]="options.name || 'Ex: 5 (km)'"
                                    [(ngModel)]="options.name"
                                    [ngModelOptions]="{standalone: true}"
                                    style="width: 70%;"
                                    type="number"
                                    (blur)="addAddress(options.name, i)"
                                ></anota-input-text>
                                <span></span>
                                <anota-input-group 
                                    [messageError]="messageError"
                                    icon="dollar-sign"  
                                    label="Valor"
                                    mask="money"
                                    searchPosition="left" 
                                    placeholder="R$ 0,00"
                                    [(ngModel)]="options.value"
                                    [ngModelOptions]="{standalone: true}"
                                >R$ </anota-input-group>
                                <anota-button *ngIf="radius.options.length > 1" icon="trash-2" type="link" size="sm" 
                                (click)="removeOptionRadius(radius, i)"></anota-button>
                                <anota-button icon="plus-circle" type="link" size="sm" [ngClass]="i == radius.options.length - 1 ? '' : 'btn-add'"
                                (click)="addOptionRadius(radius, i)" style="justify-content: end !important;"></anota-button>
                            </div>
                        </div>
                    </div>
                    <anota-radio-card size="md" value="Mesmo valor para qualquer localidade">
                        Mesmo valor para qualquer localidade
                    </anota-radio-card>
                </anota-radio-card-group>
                <br>
                <div *ngIf="myForm.controls?.attendance_model.value == 'Mesmo valor para qualquer localidade'" class="group">
                    <anota-input-group 
                    [messageError]="messageError"
                    icon="dollar-sign"  
                    label="Valor *" 
                    mask="money" 
                    searchPosition="left" 
                    placeholder="R$ 0,00"
                    formControlName="attendance_city_value"
                    
                    ></anota-input-group>
                </div>
            </form>
        </div>
    </div>
    <anota-footer [noSidebar]="true" [noMenuFooter]="true" class="footer">
        <div class="button">
            <anota-button size="lg" type="outline-primary" (click)="returnPage()">Anterior</anota-button>
            <anota-button size="lg" (click)="createForm()" [disabled]="myForm.invalid">Próximo</anota-button>
        </div>
    </anota-footer>
</div>